import React, { useState, useEffect, useCallback } from 'react';
import {
  Form,
  Input,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Card,
  Select,
  // Radio,
} from 'antd';
import { message } from 'components/common';
import { FormLabel } from 'components/common';
// import { leaveEditForm } from 'utils';
import { LoadingOutlined, SaveOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
// import { getSystemSettingList } from 'services/systemSetting';
import { SelectWarehouse } from 'components/common';
import { updatePackageAndServiceShipAccount } from 'services/storeSetup';
import {
  getShipAccountWithShoppingAround,
  fetchServiceListByShipAccount,
} from 'services/ship';
import { fetchPackageAggregateListByCarrier } from 'services/shipment';
import { getCustomers } from 'services/return';

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  service: any;
  store?: StoreChannelInfo;
  shipList: any[];
  carriers: CarrierRow[];
  // thirdPartyList: BillThirdPartyRow[];
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const {
    visible,
    onHide,
    onRefresh,
    service,
    store,
    carriers,
    // shipList,
    // thirdPartyList,
  } = props;
  const [loading, setLoading] = useState(false);
  const [loading1, setLoaing1] = useState(false);
  const [loading3, setLoaing3] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [serviceList, setServiceList] = useState<any[]>([]);
  const [defaultPackageList, setDefaultPackageList] = useState<
    ShipmentPackageRow[]
  >([]);
  const [userPackageList, setUserPackageList] = useState<ShipmentPackageRow[]>(
    []
  );
  const [shipAccountList, setShipAccountList] = useState<
    ShipAccountRow[] | undefined
  >();
  const [selectedChannel, setSelectedChannel] = useState<ShipAccountRow>();
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<
    number | undefined
  >();
  const [selectedCarrierNum, setSelectedCarrierNum] = useState<
    number | undefined
  >();
  // const [selectedChannel, setSelectedChannel] = useState<ShipAccountRow>(
  //   service
  //     ? shipList.filter(
  //         (item) => item.shipAccountNum === service.shipAccountNum
  //       )[0]
  //     : undefined
  // );
  const [selectedType, setSelectedType] = useState(0);
  const [form] = Form.useForm();

  useEffect(()=>{
    setSelectedType(service? service.type : 0)
  },[service])

  const getCustomerInfo = useCallback(async () => {
    try {
      setLoaing3(true)
      const res = await getCustomers()
      setLoaing3(false)
      if (res.isSuccess) {
        setCustomers(res.data)
      }
    } catch (error) {
      setLoaing3(false)
    }
  }, [])

  useEffect(() => {
    getCustomerInfo()
  }, [getCustomerInfo])

  const getServiceByshipAccount = async (id: number) => {
    try {
      setLoading(true);
      const res = await fetchServiceListByShipAccount(id);
      setLoading(false);
      if (res.isSuccess) {
        setServiceList(res.data);
      } else {
        message.error({ content: res.message });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getAllPackage = async (warehouseId: number) => {
    try {
      setLoaing1(true);
      const res = await fetchPackageAggregateListByCarrier(warehouseId);
      setLoaing1(false);
      if (res) {
        setDefaultPackageList(res.carrierPackages ? res.carrierPackages : []);
        setUserPackageList(res.customPackages ? res.customPackages : []);
      }
    } catch (error) {
      setLoaing1(false);
    }
  };

  const fetchShipAccountByWarehouse = async (
    warehouseId: number,
    carrierId: number,
    channelAccountNum: number,
    first?: boolean
  ) => {
    try {
      setLoading(true);
      const res = await getShipAccountWithShoppingAround(
        warehouseId,
        carrierId,
        channelAccountNum
      );
      setLoading(false);
      if (res.isSuccess) {
        setShipAccountList(res.data);
        if (first) {
          getServiceByshipAccount(service.shipAccountNum);
          setSelectedChannel(
            res.data.filter(
              (item: any) => item.shipAccountNum === service.shipAccountNum
            )[0]
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields || !store) return;
    console.log(params);
    const selectedService = serviceList.filter(
      (item) => item.carrierServiceNum === params.carrierServiceNum
    )[0];
    const temp = defaultPackageList.filter(
      (item) => item.shippingPackageNum === params.shippingPackageNum
    );
    const temp1 = userPackageList.filter(
      (item) => item.shippingPackageNum === params.shippingPackageNum
    );
    const selectedPackage =
      temp.length > 0 ? temp[0] : temp1.length > 0 ? temp1[0] : undefined;
    // if (!selectedPackage) return;
    const postParams = {
      ChannelAccountServiceNum: service ? service.channelAccountServiceNum : 0,
      Name: params.serviceKey,
      ChannelAccountNum: store.channelAccountNum,
      ShipAccountNum: params.shippingAccount,
      CarrierNum: params.carrierNum,
      Carrier: carriers.filter(
        (item) => item.carrierNum === params.carrierNum
      )[0].name,
      CarrierServiceNum: selectedService.carrierServiceNum,
      Service: selectedService.carrierServiceName,
      PackageNum: selectedPackage?.shippingPackageNum,
      Package: selectedPackage?.name,
      WarehouseNum: params.shipFrom.warehouseNum || params.shipFrom.id,
      // SignatureRequired: params.SignatureRequired,
      SaturdayDelivery: params.SaturdayDelivery,
      Customer: params.Customer,
      Type: params.Type || 0,
      ShippingMethodDescription: params.ShippingMethodDescription,
      // BillType: params.BillType,
      // ChannelAccountBillThirdPartyNum: 0,
      // params.ChannelAccountBillThirdPartyNum || 0,
      // BillThirdPartyAccount: params.billThirdPartyAccount,
      // BillThirdPartyContry: params.billThirdPartyContry,
      // BillThrdPartyPostcode: params.billThrdPartyPostcode,
    };
    try {
      setSubmitLoading(true);
      const res = await updatePackageAndServiceShipAccount(postParams);
      setSubmitLoading(false);
      if (res.isSuccess) {
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  /* eslint-disable */
  useEffect(() => {
    // getAllService();
    // getAllPackage();
    if (service && store) {
      setSelectedWarehouseId(service.warehouseNum);
      fetchShipAccountByWarehouse(
        service.warehouseNum,
        service.carrierNum,
        store.channelAccountNum,
        true
      );
      getAllPackage(service.warehouseNum);
    }
  }, [service, store]);
  useEffect(() => {
    if (
      store &&
      selectedWarehouseId &&
      (selectedCarrierNum || selectedCarrierNum === 0)
    ) {
      fetchShipAccountByWarehouse(
        selectedWarehouseId,
        selectedCarrierNum,
        store.channelAccountNum
      );
    }
  }, [selectedCarrierNum, selectedWarehouseId, store]);
  /* eslint-enable */
  return (
    <Modal
      title={`${service ? 'Edit' : 'Add'} Service Mapping for ${store?.channelAccountName
        }`}
      centered
      visible={visible}
      maskClosable={false}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
              icon={service ? <SaveOutlined /> : <PlusOutlined />}
            >
              {submitLoading
                ? `${service ? 'Saving' : 'Adding'}`
                : `${service ? 'Save' : 'Add'}`}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading || loading1 || loading3 }
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        {/* <Text>
          We don't want to assume anything, so if you tell us how you'd like to
          handle FedEx Ground in the future, we'll automatically apply these
          settings for you.
        </Text> */}
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="Type"
            initialValue={service ? service.type : 0}
            label={<FormLabel>Type</FormLabel>}
            rules={[
              { required: true, message: 'Please input Requested Service!' },
            ]}
          >
            <Select
              disabled={!!service}
              onChange={(value)=>{
                if(value !== 0){
                  form.setFieldsValue({
                    serviceKey: undefined
                  })
                }
                setSelectedType(value)
              }}
            >
              <Select.Option value={0}>Specified Request Code</Select.Option>
              <Select.Option value={1}>Empty Request Service</Select.Option>
              <Select.Option value={2}>Unspecified Request Service</Select.Option>
            </Select>
            {/* <Radio.Group onChange={(e)=>{
              if(e.target.value !== 0){
                form.setFieldsValue({
                  serviceKey: undefined
                })
              }
              setSelectedType(e.target.value)
              }}>
              <Radio value={0}>Specified Request Code</Radio>
              <Radio value={1}>Empty Request Service</Radio>
              <Radio value={2}>Unspecified Request Service</Radio>
            </Radio.Group> */}
          </Form.Item>

          <Form.Item
            name="serviceKey"
            initialValue={service ? service.name : undefined}
            label={<FormLabel>Requested Service</FormLabel>}
            rules={[
              { required: selectedType === 0, message: 'Please input Requested Service!' },
            ]}
          >
            <Input placeholder="input Service Key" disabled={selectedType !== 0}/>
          </Form.Item>

          <Card title="Shipping Settings" size="small">
            <Form.Item
              name="shipFrom"
              initialValue={service ? { id: service.warehouseNum } : undefined}
              label={<FormLabel>Warehouse </FormLabel>}
              rules={[{ required: true, message: 'please select warehouse' }]}
            >
              <SelectWarehouse
                width={423}
                onChange={(warehouse) => {
                  if (selectedWarehouseId && selectedWarehouseId >= 0) {
                    form.setFieldsValue({
                      shippingAccount: undefined,
                      carrierServiceNum: undefined,
                      shippingPackageNum: undefined,
                    });
                  }
                  setSelectedChannel(undefined);
                  setSelectedWarehouseId(warehouse.id);
                  getAllPackage(warehouse.id);
                }}
                placeholder="please select warehouse"
              />
            </Form.Item>

            <Form.Item
              name="Customer"
              initialValue={service && service.customer ? service.customer : ''}
              label={<FormLabel>Customer</FormLabel>}
            >
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option) {
                      const { children = '', value = '' } = option;
                      return (typeof children === 'string' && children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                        || (typeof value === 'string' && value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                    }

                    return false;
                  }}
                >
                    <Select.Option key='allCustomer' value=''>
                      All Customer
                    </Select.Option>
                  {customers.map(item =>
                    <Select.Option key={item.customerCode} value={item.customerCode}>
                      {`${item.customerName} (${item.customerCode})`}
                    </Select.Option>
                  )}
                </Select>
            </Form.Item>

            <Form.Item
              name="carrierNum"
              initialValue={service ? service.carrierNum : undefined}
              label={<FormLabel>Carrier </FormLabel>}
              rules={[{ required: true, message: 'please select Carrier' }]}
            >
              <Select
                showSearch
                optionFilterProp="label"
                onChange={(carrierNum) => {
                  form.setFieldsValue({
                    shippingAccount: undefined,
                    carrierServiceNum: undefined,
                    shippingPackageNum: undefined,
                  });
                  setSelectedChannel(undefined);
                  setSelectedCarrierNum(Number(carrierNum));
                }}
              >
                {carriers.map((item) => (
                  <Select.Option
                    key={item.carrierNum}
                    item={item.carrierNum}
                    value={item.carrierNum}
                    label={item.name}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="shippingAccount"
              initialValue={service ? service.shipAccountNum : undefined}
              label={<FormLabel>Shipping Account</FormLabel>}
              rules={[
                { required: true, message: 'please select a shipping account' },
              ]}
            >
              <Select
                disabled={!shipAccountList}
                showSearch
                optionFilterProp="label"
                onChange={(e) => {
                  form.setFieldsValue({
                    carrierServiceNum: undefined,
                    shippingPackageNum: undefined,
                  });
                  getServiceByshipAccount(Number(e));
                  setSelectedChannel(
                    shipAccountList &&
                    shipAccountList.filter(
                      (item) => item.shipAccountNum === e
                    )[0]
                  );
                }}
              >
                {shipAccountList &&
                  shipAccountList.map((item, index) => (
                    <Select.Option
                      value={item.shipAccountNum}
                      key={index}
                      label={item.name}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="carrierServiceNum"
              initialValue={service ? service.carrierServiceNum : undefined}
              label={<FormLabel>Service</FormLabel>}
              rules={[{ required: true, message: 'please select a service' }]}
            >
              <Select
                disabled={!selectedChannel}
                showSearch
                optionFilterProp="label"
              >
                {serviceList.map((item) => (
                  <Select.Option
                    key={item.carrierServiceNum}
                    value={item.carrierServiceNum}
                    label={item.carrierServiceName}
                  >
                    {item.carrierServiceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="shippingPackageNum"
              initialValue={service ? service.packageNum : undefined}
              label={<FormLabel>Package</FormLabel>}
            // rules={[{ required: true, message: 'please select a package' }]}
            >
              <Select
                disabled={!selectedChannel}
                allowClear
                showSearch
                optionFilterProp="label"
              >
                <Select.OptGroup label="Custom">
                  {userPackageList.map((item) => (
                    <Select.Option
                      value={item.shippingPackageNum}
                      key={item.shippingPackageNum}
                      label={item.name}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
                <Select.OptGroup label="System">
                  {defaultPackageList.map((item) =>
                    selectedChannel?.carrierNum === item.carrierNum ? (
                      <Select.Option
                        value={item.shippingPackageNum}
                        key={item.shippingPackageNum}
                        label={item.name}
                      >
                        {item.name}
                      </Select.Option>
                    ) : null
                  )}
                </Select.OptGroup>
              </Select>
            </Form.Item>

            {/* <Form.Item
              name="SignatureRequired"
              initialValue={service ? service.signatureRequired : undefined}
              label={<FormLabel>Signature Required</FormLabel>}
            >
              <Select>
                <Select.Option value={2}>Signature Required</Select.Option>
                <Select.Option value={0}>
                  Adult Signature Required
                </Select.Option>
                <Select.Option value={1}>
                  Indirect Signature Required
                </Select.Option>
              </Select>
            </Form.Item> */}

            <Form.Item
              name="SaturdayDelivery"
              initialValue={service ? (service.saturdayDelivery ? 1 : 0) : 0}
              label={<FormLabel>Saturday Delivery</FormLabel>}
              rules={[
                {
                  required: true,
                  message: 'please select a Signature Required',
                },
              ]}
            >
              <Select>
                <Select.Option value={0}>No Support</Select.Option>
                <Select.Option value={1}>Support</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="ShippingMethodDescription"
              initialValue={service ? service.shippingMethodDescription : undefined}
              label={<FormLabel>Shipping method description</FormLabel>}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item
              name="BillType"
              initialValue={service ? service.billType : undefined}
              label={<FormLabel>Bill Type</FormLabel>}
              rules={[{ required: true, message: 'please select a Bill Type' }]}
            >
              <Select>
                <Select.Option value={0}>Shipper</Select.Option>
                <Select.Option value={2}>Third Party</Select.Option>
              </Select>
            </Form.Item> */}
          </Card>

          {/* <Card
            title="Bill 3rd Party"
            size="small"
            style={store?.dropShip ? { marginTop: 10 } : { display: 'none' }}
          >
            <Form.Item
              name="ChannelAccountBillThirdPartyNum"
              initialValue={
                service ? service.ChannelAccountBillThirdPartyNum : undefined
              }
              label={<FormLabel>Bill Third Party</FormLabel>}
            >
              <Select>
                {thirdPartyList.map((item) => (
                  <Select.Option
                    key={item.channelAccountBillThirdPartyNum}
                    value={item.channelAccountBillThirdPartyNum}
                    label={item.account}
                  >
                    {`${item.account}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}

          {/* <Form.Item
              name="billThirdPartyAccount"
              initialValue={service ? service.billThirdPartyAccount : undefined}
              label={<FormLabel>Acount#</FormLabel>}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="billThirdPartyContry"
              initialValue={service ? service.billThirdPartyContry : undefined}
              label={<FormLabel>Country</FormLabel>}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="billThrdPartyPostcode"
              initialValue={service ? service.billThrdPartyPostcode : undefined}
              label={<FormLabel>Postal Code</FormLabel>}
            >
              <Input />
            </Form.Item> */}
          {/* </Card> */}
        </Form>
      </Spin>
    </Modal>
  );
};
