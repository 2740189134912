import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { CaretDownOutlined, DeleteOutlined, EditOutlined, SearchOutlined, SettingFilled } from '@ant-design/icons';
import {
  Button,
  Card,
  Row,
  Input,
  Form,
  DatePicker,
  Col,
  Space,
  Typography,
  Select,
  Dropdown,
  Menu,
} from 'antd';
import {
  ColumnDataType,
  DataGridPlus,
  FormLabel,
  HeaderTitle,
  SectionWrapper,
  SetDefaultWarehouseDialog,
  message,
  DeleteButton,
  TablesFilter,
} from 'components/common';
import { DEFAULT_DATE_TIME_FORMAT, DEFAULT_US_DATE_FORMAT, LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { getPoReceiveList, delPo } from 'services/purchaseOrder';
import {
  // convertToUtcTime,
  getWarehouseCodeFromCache,
  getWarehouseIdFromCache,
  getProfileSettingValue,
  onSelectionChange,
} from 'utils';
import CloseReceiveDialog from './CloseReceiveDialog';
import EditDialog from './EditDialog';
import { userProfiles } from 'utils/auth';
import { useLocation } from 'react-router-dom';


const { RangePicker } = DatePicker;

export default function PoList() {
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const dwarehouse = searchParams.get('warehouse');
  const [poLineList, setPoLineList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [editDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
  const [current, setCurrent] = React.useState<StringKAnyVPair>();
  const [dialogClosable, setDialogClosable] = React.useState<boolean>(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<any>({});
  const [batchCodeType, setBatchCodeType] = React.useState<string>('1');
  const [inited, setInited] = useState(false);
  const [allTempList, setAllTempList] = useState<any[]>([])
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const enableLOT = userProfiles.getProfileSettingValue("EnableLOT");
  const enableUOM = userProfiles.getProfileSettingValue("EnableUOM");

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  const [form] = Form.useForm();
  /**
   * handle search event
   */
  const handleSearch = useCallback(async () => {
    try {
      // {vendorCode: undefined, poNumber: undefined, sku: undefined, enterDate: Array(2)}
      const formValues = form.getFieldsValue();
      setLoading(true);
      const res = await getPoReceiveList(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        ),
        {
          warehouseType: formValues.warehouseType,
          vendorCode: formValues.vendorCode,
          poNumber: formValues.poNumber,
          sku: formValues.sku,
          startDate: formValues.receiveDate
            ? moment(formValues.receiveDate[0]).format('YYYY-MM-DD')
            : // convertToUtcTime(formValues.receiveDate[0].format())
            undefined,
          endDate: formValues.receiveDate
            ? moment(formValues.receiveDate[1]).format('YYYY-MM-DD')
            : // convertToUtcTime(formValues.receiveDate[1].format())
            undefined,
          startEnterDate: formValues.createDate
            ? moment(formValues.createDate[0]).startOf('day').format()
            : // convertToUtcTime(formValues.receiveDate[0].format())
            undefined,
          endEnterDate: formValues.createDate
            ? moment(formValues.createDate[1]).endOf('day').format()
            : // convertToUtcTime(formValues.receiveDate[1].format())
            undefined,
          // createDate: formValues.createDate
          //   ? `${moment(formValues.createDate[0]).format(
          //       'YYYY-MM-DD'
          //     )}@${moment(formValues.createDate[1]).format('YYYY-MM-DD')}`
          //   : undefined,
        }
      );
      setLoading(false);
      if (res.isSuccess) {
        setSelectedRow([]);
        setSelected({});
        setPoLineList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [form]);

  const delPoRecive = useCallback(async (poInfo: StringKAnyVPair) => {
    setLoading(true);
    try {
      const res = await delPo(poInfo?.poReceiveId);
      if (res) {
        message.success({
          content: `Deleted PO Receive (${poInfo.poReceiveId}) Successfully`,
        });
        handleSearch();
      } else {
        // message.error({ content: 'Delete failed' });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  },[handleSearch]);

  const getBatchNumberType = useCallback(async () => {
    try {
      const generateBatchNumber = await getProfileSettingValue(
        'GenerateBatchNumber'
      );
      setBatchCodeType(generateBatchNumber);
    } catch (error) { }
  }, []);

  const postCloseReceive = useCallback(async () => {
    const checkVendor = selectedRow.every(
      (i) => i.vendorCode === selectedRow[0].vendorCode
    );
    const checkPO = selectedRow.every(
      (i) => i.poNumber === selectedRow[0].poNumber
    );
    if (batchCodeType === '1') {
      if (!checkVendor)
        return message.info({
          content: 'Only supports batch operation of a single vendorCode',
        });
    } else {
      if (!checkPO)
        return message.info({
          content: 'Only supports batch operation of a single PO Number',
        });
    }

    setDialogVisible(true);
  }, [selectedRow, batchCodeType]);

  const columns = useMemo(()=>{
    const cls =  [
      {
        header: 'Vendor Code',
        name: 'vendorCode',
        userSelect: true,
        defaultFlex: 1,
        sortable: true,
      },
      {
        header: 'PO Number',
        name: 'poNumber',
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
      },
      {
        header: 'Container #',
        name: 'container',
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
      },
      {
        header: 'SKU',
        name: 'sku',
        minWidth: 220,
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
      },
      {
        minWidth: 130,
        name: 'lotNumber',
        header: 'LOT#',
        defaultFlex: 1,
        lotColumn: true,
        userSelect: true,
      },
      {
        header: 'Fulfillment PO Line Num',
        name: 'fulfillmentPoLineNum',
        defaultFlex: 1,
        defaultVisible: false,
        // hideable: false,
      },
      {
        header: 'Receive Qty',
        name: 'receiveQty',
        defaultFlex: 1,
        type: 'number',
      },
      {
        header: 'Closed Receive',
        name: 'closeReceiveQty',
        defaultFlex: 1,
        type: 'number',
      },
      {
        header: 'Location',
        name: 'locationCode',
        defaultFlex: 1,
      },
      {
        minWidth: 130,
        name: 'uom',
        header: 'UOM',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'uomRate',
        header: 'UOM Rate',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'uomBaseQty',
        header: 'Base Qty',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        header: 'Receive Date',
        name: 'receiveDate',
        defaultFlex: 1,
        sortable: true,
        userSelect: true,
        minWidth: 220,
        render: (row: any) => {
          const { data } = row;
          return moment(data.receiveDate).format(DEFAULT_US_DATE_FORMAT)
        }
      },
      {
        header: 'Enter Date',
        name: 'createDate',
        defaultFlex: 1,
        sortable: true,
        userSelect: true,
        dataType: ColumnDataType.DATE,
        minWidth: 220,
        render: (value: any) => {
          const { data } = value;
          return <Space>{moment(data.createDate).format(DEFAULT_DATE_TIME_FORMAT)}</Space>;
        },
      },
      {
        header: 'Received Batch',
        name: 'batchNumber',
        userSelect: true,
        defaultFlex: 1,
      },
      // {
      //   header: 'Sync Status',
      //   name: 'syncStatus',
      //   defaultFlex: 1,
      // },
      {
        name: 'action',
        header: 'Action',
        showColumnMenuTool: false,
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        defaultFlex: 1,
        defaultLocked: 'end',
        minWidth: 100,
        render: (value: any) => {
          const { data } = value;
          return (
            // <Space>
            //   <Button
            //     onClick={() => {
            //       setEditDialogVisible(true);
            //       setCurrent(data);
            //     }}
            //   >
            //     <EditOutlined />
            //     Edit
            //   </Button>
            //   {!data.isSysDefined && (
            //     <DeleteButton
            //       confirmContent={`PO Receive Id : ${data.poReceiveId}`}
            //       confirmTitle="Are you sure you want to delete the Item ?"
            //       onClick={() => delPoRecive(data)}
            //     />
            //   )}
            // </Space>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="0">
                    <Button
                      style={{ width: '100%', textAlign: 'left' }}
                      type="text"
                      onClick={() => {
                        setEditDialogVisible(true);
                        setCurrent(data);
                      }}
                      icon={<EditOutlined />}
                    >
                      Edit
                    </Button>
                  </Menu.Item>
                  <Menu.Item key="1">
                    <DeleteButton
                      style={{ width: '100%', textAlign: 'left' }}
                      type="text"
                      confirmContent={`PO Receive ID : ${data.poReceiveId}`}
                      confirmTitle="Are you sure you want to delete the item ?"
                      showIcon={false}
                      icon={<DeleteOutlined />}
                      onClick={() => delPoRecive(data)}
                    />
                  </Menu.Item>
                </Menu >
              }
              trigger={['click']}
            >
              <Button
                icon={<SettingFilled />}
              >
                <CaretDownOutlined />
              </Button>
            </Dropdown >
          );
        },
      },
    ];
    let temp = [...cls]
    temp = enableLOT === '1' ? [...temp] : temp.filter(i=> !i.lotColumn)
    temp = enableUOM === '1' ? [...temp] : temp.filter(i => !i.uomColumn)
    return temp;
  },[delPoRecive, enableLOT, enableUOM])


  React.useEffect(() => {
    if(!inited){
      getBatchNumberType();
      setInited(true)
      if(dwarehouse){
        handleSearch()
      }
    }
  }, [getBatchNumberType, inited, dwarehouse,handleSearch]);

  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'PO',
          `PO Receive Batch  (Warehouse : ${getWarehouseCodeFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}）`,
        ]}
      >
        <Space>
          {inited && (
            <Typography.Text type="secondary">
              {
                `Generate batch number by ${batchCodeType === '1' ? 'vendor' : batchCodeType === '2' ? 'PO#' : 'Container#'}`
              }
            </Typography.Text>
          )}
          <Button
            type="primary"
            disabled={selectedRow.length === 0}
            onClick={postCloseReceive}
          >
            Close Receive
          </Button>
        </Space>
      </HeaderTitle>
      <Form form={form}>
        <SectionWrapper
          style={
            {
              // display: 'flex',
              // flexDirection: 'row',
              // alignItems: 'center',
              // justifyContent: 'space-between',
            }
          }
        >
          <Row>
            <Col style={{ alignContent: 'center', padding: 4 }} span={6}>
              <FormLabel>Warehouse</FormLabel>
              <Form.Item noStyle name="warehouseType">
                <Select defaultValue={0} style={{ width: '100%' }}>
                  <Select.Option value={0}>All</Select.Option>
                  <Select.Option value={1}>Normal</Select.Option>
                  <Select.Option value={6}>Receiving</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ alignContent: 'center', padding: 4 }} span={6}>
              <FormLabel>Vendor Code</FormLabel>
              <Form.Item noStyle name="vendorCode">
                <Input />
              </Form.Item>
            </Col>
            <Col style={{ alignContent: 'center', padding: 4 }} span={6}>
              <FormLabel>PO Number</FormLabel>
              <Form.Item noStyle name="poNumber">
                <Input />
              </Form.Item>
            </Col>
            <Col style={{ alignContent: 'center', padding: 4 }} span={6}>
              <FormLabel>Container #</FormLabel>
              <Form.Item noStyle name="container">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ alignContent: 'center', padding: 4 }} span={6}>
              <FormLabel>SKU</FormLabel>
              <Form.Item noStyle name="sku">
                <Input />
              </Form.Item>
            </Col>
            <Col style={{ alignContent: 'center', padding: 4 }} span={6}>
              <FormLabel>Receive Date</FormLabel>
              <Form.Item
                noStyle
                // initialValue={[
                //   moment().startOf('days'),
                //   moment().add(1, 'days').endOf('days'),
                // ]}
                name="receiveDate"
              >
                <RangePicker
                  style={{ width: '100%' }}
                  showTime
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [
                      moment().add(-1, 'day').startOf('day'),
                      moment().add(-1, 'day').endOf('day'),
                    ],
                    '2 days ago': [
                      moment().add(-2, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    '3 days ago': [
                      moment().add(-3, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    'This Week': [
                      moment().startOf('week'),
                      moment().endOf('week'),
                    ],
                    'Last Week': [
                      moment().add(-1, 'week').startOf('week'),
                      moment().add(-1, 'week').endOf('week'),
                    ],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                  format={DEFAULT_US_DATE_FORMAT}
                // disabledDate={rangePickerDisabledDate}
                />
              </Form.Item>
            </Col>
            <Col style={{ alignContent: 'center', padding: 4 }} span={6}>
              <FormLabel>Enter Date</FormLabel>
              <Form.Item
                noStyle
                name="createDate"
                initialValue={dwarehouse ? undefined : [
                  moment().add(-1, 'month').startOf('days'),
                  moment().add(1, 'days').endOf('days'),
                ]}
              >
                <RangePicker
                  style={{ width: '100%' }}
                  showTime
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [
                      moment().add(-1, 'day').startOf('day'),
                      moment().add(-1, 'day').endOf('day'),
                    ],
                    '2 days ago': [
                      moment().add(-2, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    '3 days ago': [
                      moment().add(-3, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    'This Week': [
                      moment().startOf('week'),
                      moment().endOf('week'),
                    ],
                    'Last Week': [
                      moment().add(-1, 'week').startOf('week'),
                      moment().add(-1, 'week').endOf('week'),
                    ],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                  format={DEFAULT_DATE_TIME_FORMAT}
                // disabledDate={rangePickerDisabledDate}
                />
              </Form.Item>
            </Col>
            <Col
              style={{ alignContent: 'center', padding: 4, alignSelf: 'end' }}
              span={6}
            >
              <Button loading={loading} type="primary" onClick={handleSearch}>
                <SearchOutlined />
                <span>Search</span>
              </Button>
            </Col>
          </Row>
        </SectionWrapper>
      </Form>
      <Card bodyStyle={{ padding: 4 }}>
        <TablesFilter
          columns={columns}
          dataSource={poLineList}
          setFilteredData={setFilteredData}
        />
        <DataGridPlus
          name="ListpoReceive"
          showScrollButton
          style={{ minHeight: 300 }}
          idProperty="poReceiveNum"
          checkboxOnlyRowSelect={true}
          selected={selected}
          onSortInfoChange={() => {
            setSelected({})
            setSelectedRow([])
          }}
          onChange={() => {
            setSelected({})
            setSelectedRow([])
          }}
          checkboxColumn
          autoWith={false}
          onSelectionChange={(props) => onSelectionChange(props, allTempList, setSelected, setSelectedRow, 'poReceiveNum', true, setAllTempList)}
          // onSelectionChange={(props) => {
          //   const { selected, data, unselected } = props;
          //   setSelected(selected);

          //   if (selected === true && !unselected) {
          //     setSelectedRow((data as any) || []);
          //   } else {
          //     if (!unselected) {
          //       setSelectedRow(Object.values(selected as any));
          //     } else {
          //       setSelectedRow((prev) => {
          //         return prev.filter(
          //           (item) => item.poReceiveNum !== (data as any).poReceiveNum
          //         );
          //       });
          //     }
          //   }
          // }}
          columns={columns}
          //   count={0}
          dataSource={filteredData}
          //   onChange={handleDataGridChange}
          pagination="local"
          loading={loading}
          /* eslint-disable react/jsx-props-no-spreading */
          {...({} as any)}
        />
      </Card>
      {dialogVisible && (
        <CloseReceiveDialog
          visible
          onHide={() => setDialogVisible(false)}
          onRefresh={() => handleSearch()}
          warehouseId={getWarehouseIdFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}
          selectedRow={selectedRow}
          generateBatchNumber={batchCodeType}
        />
      )}
      {editDialogVisible && current && (
        <EditDialog
          visible
          warehouseId={getWarehouseIdFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}
          onHide={() => setEditDialogVisible(false)}
          onRefresh={() => handleSearch()}
          poInfo={current}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </>
  );
}
